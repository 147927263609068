import React, { useReducer, createContext, useEffect, lazy, Suspense } from 'react';
import './App.css';
import { NotificationContainer } from 'react-notifications'

import { Switch, Route } from 'react-router-dom';
import firebase, { User } from './firebase';

import Loading from './components/Loading';
import Navbar from './components/Navbar';
import PageNotFound from './pages/PageNotFound';

const LandingPage = lazy(() => import('./pages/LandingPage'))
const ArticlesPage = lazy(() => import('./pages/Articles'))
const Login = lazy(() => import('./pages/Login'))
const EditorPage = lazy(() => import('./pages/Editor'))
const Preview = lazy(() => import('./pages/Preview'))

// ===================================== TYPES AND INTERFACES =======================================

export type GlobalState = {
  user: User | null;
  articles: Record<string, Article>;
  isLoading: boolean;
}

export interface GlobalContext extends GlobalState {
  setArticles?: (data: Record<string, Article>) => any;
  loginUser?: (data: User) => any;
  logoutUser?: () => any;
  setLoading?: (val: boolean) => any;
}

export type Article = {
  title: string;
  summary: string;
  author: string;
  content: string;
  timestamp: Date;
}

export type ReducerAction = {
  type: string;
  payload?: any;
}

// ===================================== GLOBAL CONTEXT ==============================================

const initialState: GlobalContext = {
  user: null,
  articles: {},
  isLoading: false
}

export const Store = createContext(initialState)

// ===================================================================================================

// Initial state
const initial: GlobalState = {
  user: null,
  articles: {},
  isLoading: true
}

const reducer = (state: GlobalState, action: ReducerAction): GlobalState => {
  console.log(action.type, state)
  switch (action.type) {
    case "ADD_ARTICLES":
      return { ...state, articles: { ...state.articles, ...action.payload } }
    case "LOGIN_USER":
      return { ...state, user: action.payload }
    case "LOGOUT_USER":
      return { ...state, user: null }
    case "SET_LOADING":
      return { ...state, isLoading: action.payload }
    default:
      return state;
  }
}

// ===================================================================================================

function App() {
  const [state, dispatch] = useReducer(reducer, initial)
  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true })
    firebase.auth().onAuthStateChanged(user => {
      if (user) dispatch({ type: "LOGIN_USER", payload: user })
      else dispatch({ type: "LOGOUT_USER" })
      dispatch({ type: "SET_LOADING", payload: false })
    })
  }, [])

  return (
    <Store.Provider value={{
      ...state,
      setArticles: (articles) => dispatch({ type: "ADD_ARTICLES", payload: articles }),
      loginUser: (user) => dispatch({ type: "LOGIN_USER", payload: user }),
      logoutUser: () => dispatch({ type: "LOGOUT_USER" }),
      setLoading: (val) => dispatch({ type: "SET_LOADING", payload: val })
    }}>
      <div className="App">
        <Navbar />
        {!state.isLoading
          ?
          <Switch>
            <Route path="/blogs" exact>
              <Suspense fallback={<Loading />}>
                <ArticlesPage />
              </Suspense>
            </Route>
            <Route path="/editor" exact>
              <Suspense fallback={<Loading />}>
                <EditorPage />
              </Suspense>
            </Route>
            <Route path="/blogs/:id" exact>
              <Suspense fallback={<Loading />}>
                <Preview />
              </Suspense>
            </Route>
            <Route path="/login" exact>
              <Suspense fallback={<Loading />}>
                <Login />
              </Suspense>
            </Route>
            <Route path="/" exact>
              <Suspense fallback={<Loading />}>
                <LandingPage />
              </Suspense>
            </Route>
            <Route path={["/404", "/"]}>
              <Suspense fallback={<Loading />}>
                <PageNotFound />
              </Suspense>
            </Route>
          </Switch>
          :
          <Loading />
        }
      </div>
      <NotificationContainer />
    </Store.Provider>
  );
}

export default App;
