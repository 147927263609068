import React from 'react'
import Classes from './index.module.css'
import Spinner from '../Spinner'

function Loading() {
    return (
        <div className={Classes.container}>
            <Spinner />
        </div>
    )
}

export default Loading
