import React from 'react'
import { useHistory } from 'react-router-dom'
import Classes from './index.module.css'
import Global from '../../global/global.module.css'

function PageNotFound() {
    const history = useHistory()

    return (
        <div className={Classes.page}>
            <div className={`${Global.container} ${Classes.container}`}>
                <div className={Classes["code"]}>
                    404
                </div>
                <div className={Classes["line"]}>
                    Looks like you are lost
                </div>
                <div className={Classes.btnContainer}>
                    <button onClick={() => history.goBack()} className={Classes.btn}>
                        Go Back
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound
