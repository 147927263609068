import React from 'react'
import { Link } from 'react-router-dom';

// ================================== INTERFACES =================================
export enum LinkType {
    INTERNAL,
    EXTERNAL
}

export interface ILinkType {
    type: LinkType,
    link: string
}

export interface ILinkButton {
    name: string;
    link: ILinkType;
    className?: string;
    activeClassName?: string;
}

// ================================== COMPONENT =================================

function LinkButton({ name, link, className }: ILinkButton) {
    if (link.type === LinkType.INTERNAL)
        return (
            <Link to={link.link}>
                <div className={className}>
                    {name}
                </div>
            </Link>
        )
    else return (
        <a href={link.link} target="_blank" rel="noopener noreferrer">
            <div className={className}>
                {name}
            </div>
        </a>
    )
}

export default LinkButton
