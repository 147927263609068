import app, { User as user } from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { Article } from './App';

export const firebaseConfig = {
    apiKey: "AIzaSyCOo09fhe20AiPT4wCTpAj2UOTHPuZDjR8",
    authDomain: "miracle-vastu.firebaseapp.com",
    databaseURL: "https://miracle-vastu.firebaseio.com",
    projectId: "miracle-vastu",
    storageBucket: "miracle-vastu.appspot.com",
    messagingSenderId: "290113705191",
    appId: "1:290113705191:web:5052e240795f57d2e57e42",
    measurementId: "G-TYT2JLWDNP"
};

export type User = user;

export class Firebase {
    /**
     * Name of the collection in the firestore
     */
    private collection = "articles";

    constructor() {
        app.initializeApp(firebaseConfig)
    }

    /**
     * Returns the firebase/app
     */
    public app() {
        return app;
    }

    /**
     * Stores the data to firestore
     * @param doc 
     */
    public async store(doc: any) {
        return app.firestore().collection(this.collection).add(doc)
    }

    /**
     * Get all the articles
     * @param page 
     * @param perPage 
     */
    public async read(page: number = 0, perPage: number = 10): Promise<Record<string, Article>> {
        return new Promise(async (resolve, reject) => {
            const res = await app.firestore().collection(this.collection).get()
            const data: Record<string, Article> = {}
            res.forEach(doc => {
                data[doc.id] = doc.data() as Article
            })
            resolve(data)
        })
    }

    /**
     * Find an article by it's id
     * @param id 
     */
    public async findByID(id: string): Promise<Record<string, Article>> {
        return new Promise(async (resolve, reject) => {
            const res = await app.firestore().collection(this.collection).doc(id).get()
            const data: Record<string, Article> = {}
            if (res.exists) data[id] = res.data() as Article
            resolve(data)
        })
    }

    get auth() {
        return app.auth
    }
}

export default new Firebase()